var w;
var h;

function calcWindowWidthAndHeight() {
    w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
}

function cookieEmergency() {
    Cookies.set('emergency-close', 'close');
    $('.emergencySticky').css('display','none');
}

$(document).ready(function(){

    var pll_strings = {
            en : {
                'close' : 'Close',
                'seeaccess' : 'See access',
                'prices' : 'See prices'
            },
            fr : {
                'close' :  'Fermer',
                'seeaccess' : 'Voir les accès',
                'prices' : 'Voir les tarifs'
            }
    };

    // $.validator.addMethod("validateRecaptcha", function (value, element) {
    //     if (grecaptcha.getResponse() == '') {
    //         return false;
    //     } else {
    //         return true;
    //     }
    // }, "Vous devez valider le reCAPTCHA");

    setTimeout(function () {
        if ($('#container_form form').length) {
            $('#contactRgpd').rules('add', {
                validateCgv: true
            });
            // $('#f_recaptcha').rules('add', {
            //     validateRecaptcha: true
            // });
        }
    }, 100);

    $.extend($.validator.messages, {
        required: "Ce champ est obligatoire.",
        email: "Veuillez fournir une adresse électronique valide."
    });

    $.validator.addMethod("validateCgv", function (value, element) {
        return $('#contactRgpd').is(':checked');
    }, "Vous devez avoir pris connaissance de notre politique de confidentialité");

    $('#container_form form').validate({
        errorElement: "span",
        errorPlacement: function (error, element) {
            error.addClass("help-block");
            if (element.attr('type') == 'radio' || element.attr('type') == 'checkbox') {
                var parentElm = element.closest('.form-group');
                error.addClass("checkbox");
                parentElm.append(error);
            }
            else
                error.insertAfter(element);
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-error").removeClass("has-success");
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-success").removeClass("has-error");
        },
        submitHandler: function (form) {
            form.submit();
        }
    });



    // DATEPICKER

    $('#start_time').datepicker({
        language: 'fr-FR',
        autoHide: true,
        format: 'dd/mm/yyyy',
        startDate: new Date(),
        weekStart: 1,
        daysMin: [ "D","L","M","M","J","V","S" ],
        months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    });

    $('#end_time').datepicker({
        language: 'fr-FR',
        autoHide: true,
        format: 'dd/mm/yyyy',
        // startDate: new Date(),
        weekStart: 1,
        daysMin: [ "D","L","M","M","J","V","S" ],
        months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    });

    $('#start_time').on('hide.datepicker', function(e) {
        $('#end_time').datepicker('setDate', $('#start_time').datepicker('getDate'));
        $('#end_time').datepicker('setStartDate', $('#start_time').datepicker('getDate'));
    });

    // Menu 
    $('.mainMenu-subMenu').click(function() {
        $('.mainMenu-subMenu').removeClass('active');
        $(this).toggleClass('active');
    });

    //Search menu

    $('.searchButton').click(function(){
        $('.headerContent').toggleClass('searchActive');
    });


    $(document).mouseup(function(e) {
        if ($('.headerContent').hasClass('searchActive')){
            var container = $(".searchContainer");

            // if the target of the click isn't the container nor a descendant of the container
            if (!container.is(e.target) && container.has(e.target).length === 0){
                $('.headerContent').removeClass('searchActive');
            }
        }
    });


    $('.owl-hpHead-slider').owlCarousel({
        loop: true,
        nav: false,
        items: 1,
        autoplay:true,
        autoplayTimeout:10000,
    });

    // OWL-carousel Block

    var IDnumber = 1;
    var NEXTPREVnumber = 1;

    $('.owl-carousel-block').each(function () {

        var newID = 'owl-carousel-block' + IDnumber;

        $(this).attr('id', newID);
        IDnumber++;

        var owl = $('#' + newID);


        owl.owlCarousel({
            loop: true,
            nav: false,
            items: 1,
            dots: false
        });

        owl.trigger('refresh.owl.carousel');

        $(this).next().addClass('owlNextBlock' + NEXTPREVnumber)
        $(this).next().next().addClass('owlPrevBlock' + NEXTPREVnumber)

        // Go to the next item
        $('.owlNextBlock' + NEXTPREVnumber).click(function () {
            owl.trigger('next.owl.carousel');
        })

        // Go to the previous item
        $('.owlPrevBlock' + NEXTPREVnumber).click(function () {
            // With optional speed parameter
            // Parameters has to be in square bracket '[]'
            owl.trigger('prev.owl.carousel', [300]);

        });

        NEXTPREVnumber++;

    });

    // OWL-TEXTE IMAGES

    $('.owl-images').owlCarousel({
        loop: true,
        nav: false,
        dots: true,
        items: 1,
        // autoplay:true,
        // autoplayTimeout:10000,
    });

    // OWL VISITES
    $('.owl-visit').owlCarousel({
        loop: true,
        nav: false,
        dots: false,
        items: 1,
        autoplay:true,
        autoplayTimeout:3000,
        smartSpeed: 2000,
    });

    // OWL-carousel Partners

    var IDnumber = 1;
    var NEXTPREVnumber = 1;

    $('.owl-carousel-partners').each(function () {

        var newID = 'owl-carousel-partners' + IDnumber;

        $(this).attr('id', newID);
        IDnumber++;

        var owl = $('#' + newID);


        owl.owlCarousel({
            loop: false,
            nav: false,
            margin: 15,
            dots: false,
            responsiveClass:true,
                responsive:{
                    0:{
                        items:2,
                    },
                    767:{
                        items:3,
                    },
                    991:{
                        items:4,
                    }
                }
        });

        owl.trigger('refresh.owl.carousel');

        $(this).next().addClass('owlNextPartners' + NEXTPREVnumber)
        $(this).next().next().addClass('owlPrevPartners' + NEXTPREVnumber)

        // Go to the next item
        $('.owlNextPartners' + NEXTPREVnumber).click(function () {
            owl.trigger('next.owl.carousel');
        })

        // Go to the previous item
        $('.owlPrevPartners' + NEXTPREVnumber).click(function () {
            // With optional speed parameter
            // Parameters has to be in square bracket '[]'
            owl.trigger('prev.owl.carousel', [300]);

        });

        NEXTPREVnumber++;

    });


    // OWL-carousel Podcasts

    var IDnumber = 1;
    var NEXTPREVnumber = 1;

    $('.owl-carousel-podcasts').each(function () {

        var newID = 'owl-carousel-podcasts' + IDnumber;

        $(this).attr('id', newID);
        IDnumber++;

        var owl = $('#' + newID);


        owl.owlCarousel({
            loop: false,
            nav: false,
            margin: 15,
            dots: false,
            responsiveClass:true,
                responsive:{
                    0:{
                        items:2,
                    },
                    767:{
                        items:3,
                    },
                    991:{
                        items:4,
                    }
                }
        });

        owl.trigger('refresh.owl.carousel');

        $(this).next().addClass('owlNextPodcasts' + NEXTPREVnumber)
        $(this).next().next().addClass('owlPrevPodcasts' + NEXTPREVnumber)

        // Go to the next item
        $('.owlNextPodcasts' + NEXTPREVnumber).click(function () {
            owl.trigger('next.owl.carousel');
        })

        // Go to the previous item
        $('.owlPrevPodcasts' + NEXTPREVnumber).click(function () {
            // With optional speed parameter
            // Parameters has to be in square bracket '[]'
            owl.trigger('prev.owl.carousel', [300]);

        });

        NEXTPREVnumber++;

    });


    // OWL-carousel Photos

    var IDnumber = 1;
    var NEXTPREVnumber = 1;

    $('.owl-carousel-photos').each(function () {

        var newID = 'owl-carousel-photos' + IDnumber;

        $(this).attr('id', newID);
        IDnumber++;

        var owl = $('#' + newID);


        owl.owlCarousel({
            loop: false,
            nav: false,
            margin: 15,
            dots: false,
            responsiveClass:true,
                responsive:{
                    0:{
                        items:2,
                    },
                    767:{
                        items:3,
                    },
                    991:{
                        items:4,
                    }
                }
        });

        owl.trigger('refresh.owl.carousel');

        $(this).next().addClass('owlNextPhotos' + NEXTPREVnumber)
        $(this).next().next().addClass('owlPrevPhotos' + NEXTPREVnumber)

        // Go to the next item
        $('.owlNextPhotos' + NEXTPREVnumber).click(function () {
            owl.trigger('next.owl.carousel');
        })

        // Go to the previous item
        $('.owlPrevPhotos' + NEXTPREVnumber).click(function () {
            // With optional speed parameter
            // Parameters has to be in square bracket '[]'
            owl.trigger('prev.owl.carousel', [300]);

        });

        NEXTPREVnumber++;

    });



    // OWL-carousel Photos

    var IDnumber = 1;
    var NEXTPREVnumber = 1;

    $('.owl-carousel-pressArticle').each(function () {

        var newID = 'owl-carousel-pressArticle' + IDnumber;

        $(this).attr('id', newID);
        IDnumber++;

        var owl = $('#' + newID);


        owl.owlCarousel({
            loop: false,
            nav: false,
            margin: 15,
            dots: false,
            responsiveClass:true,
                responsive:{
                    0:{
                        items:2,
                    },
                    767:{
                        items:3,
                    },
                    991:{
                        items:4,
                    }
                }
        });

        owl.trigger('refresh.owl.carousel');

        $(this).next().addClass('owlNextArticle' + NEXTPREVnumber)
        $(this).next().next().addClass('owlPrevArticle' + NEXTPREVnumber)

        // Go to the next item
        $('.owlNextArticle' + NEXTPREVnumber).click(function () {
            owl.trigger('next.owl.carousel');
        })

        // Go to the previous item
        $('.owlPrevArticle' + NEXTPREVnumber).click(function () {
            // With optional speed parameter
            // Parameters has to be in square bracket '[]'
            owl.trigger('prev.owl.carousel', [300]);

        });

        NEXTPREVnumber++;

    });




    function toggleLoop() {
        $('.owl-carousel-partners').each(function () {

            var itemsCount;
            var loopStatus = false;

            itemsCount = $(this).find('.owl-item:not(.cloned)').length;

            calcWindowWidthAndHeight();

            if (itemsCount > 4) {
                loopStatus = true;

            } else if (itemsCount == 4) {

                loopStatus = w < 991 ? true : false;

            } else if (itemsCount == 3) {

                loopStatus = w < 767 ? true : false;

            } else {

                loopStatus = w >= 767 ? false : false;

            }

            loopStatusCurrent = $(this).data('owl.carousel').options.loop;
            if (loopStatusCurrent != loopStatus) {
                if (loopStatus) {
                    $(this).data('owl.carousel').options.loop = true;
                    $(this).trigger('refresh.owl.carousel');
                    // L'OPTION LOOP EST TRUE
                    // Do something...
                    $(this).parent().find('.owlPrevPartners').addClass('show');
                    $(this).parent().find('.owlNextPartners').addClass('show');

                } else if (!loopStatus) {
                    $(this).data('owl.carousel').options.loop = false;
                    $(this).trigger('refresh.owl.carousel');
                    // L'OPTION LOOP EST FALSE
                    // Do something...
                    $(this).parent().find('.owlPrevPartners').removeClass('show');
                    $(this).parent().find('.owlNextPartners').removeClass('show');
                }
            }


        });
    }

    toggleLoop();
    $(window).resize(toggleLoop);



    // menu

    $('.toggleMenu').click(function(){
        $(this).toggleClass('active');
        $('.mainMenu').toggleClass('active');
        $('html').toggleClass('oh');
    });

    $('.mainMenu-subMenu').click(function(){
        calcWindowWidthAndHeight();

        if(w <= 991){
            h = $(this).find('.mainMenu-subMenuContentInner').outerHeight(true) + 20;

            if($(this).find('.mainMenu-subMenuContent').hasClass('active')){
                $('.mainMenu-subMenuContent').removeClass('active');
                $('.mainMenu-subMenuContent').css('height', 0);
            } else {
                $('.mainMenu-subMenuContent').removeClass('active');
                $('.mainMenu-subMenuContent').css('height', 0);
                $(this).find('.mainMenu-subMenuContent').addClass('active');
                $(this).find('.mainMenu-subMenuContent').css('height', h);
            }
        }
    });

    function resetHeightOnSubMenu(){
        calcWindowWidthAndHeight();

        if(w > 991){
            $('.mainMenu-subMenuContent').css('height', 64);
        } else {
            $('.mainMenu-subMenuContent').css('height', 0);
        }
    }

    resetHeightOnSubMenu();
    $(window).resize(resetHeightOnSubMenu);


    // Add arrow to each button
    $(".btn:not('#form_submit')").each(function(){
        if($(this).is('input')){

        } else {
            $(this).addClass('mylazyload');
            $(this).wrapInner('<div class="innerBtn"></div>');
            $(this).find('.innerBtn').wrapInner('<div class="innerInnerBtn"></div>');
            $(this).find( ".innerInnerBtn" ).append( '<svg version="1.1" class="arrow" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 75.28 58.48" style="enable-background:new 0 0 75.28 58.48;" xml:space="preserve"><polygon points="46.08,0.43 41.13,5.38 61.43,25.69 0,25.69 0,32.69 61.43,32.69 41.13,52.99 46.08,57.94 74.83,29.19 "/></svg>');

        }
    });

    $( ".select-selected" ).append( '<svg version="1.1" class="arrow" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 75.28 58.48" style="enable-background:new 0 0 75.28 58.48;" xml:space="preserve"><polygon points="46.08,0.43 41.13,5.38 61.43,25.69 0,25.69 0,32.69 61.43,32.69 41.13,52.99 46.08,57.94 74.83,29.19 "/></svg>');


    function mylazyload () {

        // Bas de la fenetre (en pixels)
        var windowBottom = $(window).scrollTop() + $(window).height();

        // Afficher les images et le texte quand on scroll
        $('.mylazyload').each(function(){

            // Le haut de mon Ã©lÃ©ment (y)
            var myTop = $(this).offset().top;

            // DÃ¨s que l'Ã©lÃ©ment devient visible dans la fenetre
            if (myTop < (windowBottom - 50)) {

                $(this).addClass('mylazyloaded');

                // On affiche l'image (en lazyload)
                $(this).find("img").each(function(){
                    $(this).attr("src", $(this).data('src'));
                    $(this).removeAttr('data-src');
                });

                // Bouton animation
                if($(this).hasClass('btn')){
                    if($(this).is('input')){

                    } else {

                        w = $(this).find('.innerInnerBtn').outerWidth(true) + 60;
                        wmax = $(this).parent().outerWidth(true);

                        if (wmax < w){
                            $(this).css('width', wmax);
                            $(this).find('.innerBtn').css('width', wmax);
                        } else {
                            $(this).css('width', w);
                            $(this).find('.innerBtn').css('width', w);
                        }

                        if($(this).hasClass('center')){
                            $(this).css('margin-left', -w/2);
                        }

                    }
                }


            } else {
                // L'Ã©lÃ©ment n'est pas dans la fenetre
            }

        });
    }



    mylazyload();
    $( window ).scroll(mylazyload);

    // Adjust height to hours for each little white block. (resolve height problem)
    if($('.allHours').length){
        $('.hour').each(function(){
            h = $(this).find('.date').outerHeight(true);
            $(this).find('.hourContent').css('min-height', h);
        });
    }

    // change the background color of the headerpage to white when body is grey
    if($('.headerPage').length){
        if ($('.headerPage').next().css('background-color')=="rgb(244, 244, 244)"){
            $('.headerPage').css('background', 'white');
        };
     }


     // Adjust margin top of every block form

    function marginBlockForm(){
        if($('.blockForm').length){
            $('.container_form').each(function(){
                h = $(this).parent().parent().find('.titleForm').outerHeight(true) + 30;
                $(this).css('padding-top', h);
            });
        }
    }

    marginBlockForm();
    $( window ).resize(marginBlockForm);



    /* VIDEO BG */

    var timeoutId;

    // function videobgEnlarge() {

    //     var videoBgAspect = $(".videobg-aspect");
    //     var videoBgWidth = $(".videobg-width");
    //     var videoAspect = videoBgAspect.outerHeight() / videoBgAspect.outerWidth();
    //     var windowAspect = ($(window).height() / $(window).width());

    //     if (windowAspect > videoAspect) {
    //         videoBgWidth.width((windowAspect / videoAspect) * 100 + '%');
    //     } else {
    //         videoBgWidth.width(100 + "%")
    //     }

    // }

    // $(window).resize(function() {
    //   clearTimeout(timeoutId);
    //   timeoutId = setTimeout(videobgEnlarge, 100);
    // });

    // $(function() {
    //   videobgEnlarge();
    // });
    function videoHeight() {
        var height = $('.videobg-make-height').outerHeight(true);
        console.log(height);

        $('.videobg').css('height', height);
    }

    $(window).resize(function() {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(videoHeight, 100);
    });
    $(function() {
      videoHeight();
    });

    $('#closeEmergency').click(function() {
        cookieEmergency();
    });

    if (Cookies.get('emergency-close') == 'close') {
        $('.emergencySticky').css('display','none');
    }


    // BANDEAU EMERGENCY

    // Taille de l'ecran en largeur
    var wW = $(window).width()

    // Taille du texte en largeur
    var textW = $('.infoSticky').outerWidth(true);

    // Taille du bouton de fermeture
    var closeBtnW = $('.closeBtn').outerWidth(true);

    // Taille max du décalage du texte à droite
    var maxTrans = $(window).width() - closeBtnW;

    // Taille max du décalage du texte à gauche
    var minTrans = 0 - $('.infoSticky').outerWidth(true);

    // VARS
    var textDefilVar;
    var textDefilVarToggle;

    // Faire défiler le texte de 1px vers la gauche
    function textDefil(){
        var currentTrans = parseInt($('.infoSticky').css('transform').split(',')[4]);

        if(currentTrans > minTrans){
            currentTrans--;
            $('.infoSticky').css('transform', 'translateX('+currentTrans+'px)');

        } else { // Si le texte atteint le max décalage à gauche, revient tout à droite
            currentTrans = maxTrans;
            $('.infoSticky').css('transform', 'translateX('+currentTrans+'px)');
        }
    }

    // Activer le defilement ou pas
    function emergencyStickyPosition(){
      wW = $(window).width()
      maxTrans = $(window).width() - closeBtnW;

      // Activer le defilement si le texte depasse la largeur de l'ecran
      if(textW > (wW - closeBtnW)){
        $('.infoSticky').css('transform', 'translateX('+maxTrans+'px)');

        if(!textDefilVarToggle){
          textDefilVar = setInterval(textDefil, 10);
          textDefilVarToggle = true;
        }

      // desactiver le defilement si le texte depasse la largeur de l'ecran
      } else {
        clearInterval(textDefilVar);
        textDefilVarToggle = false;
        $('.infoSticky').css('transform', 'translateX(0px)');
      }
    }

    emergencyStickyPosition();
    $( window ).resize(emergencyStickyPosition);

    var k = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65],
   n = 0;
   $(document).keydown(function (e) {
       if (e.keyCode === k[n++]) {
           if (n === k.length) {
               $('body').append('<div id="konami" style="background-color: rgba(0,0,0,0.6);flex-direction: column;display:flex;justify-content:center;align-items:center;position:fixed;top:0;left:0;bottom:0;right:0;z-index:9999;"><div style="font-size: 50px;margin-bottom: 40px;color:#beac83;font-weight:700;">Le Phare à Hon</div><iframe width="600" height="400" src="https://www.youtube.com/embed/hVh-aV2D72Q?autoplay=1" frameborder="0" allowfullscreen></iframe></div>');
               $(document).keyup(function(e) {
                     if (e.key === "Escape") { // escape key maps to keycode `27`
                        $('#konami').remove()
                    }
                })
               return false;
           }
       }
       else {
           n = 0;
       }
   });



    //VISITES



    var mediaMobile = window.matchMedia("(min-width: 991px)");

    $('.navigation').click(function() {
        var dataNav = $(this).attr('data-number');
        $('.textVisit').removeClass('active');
        $('.dot').removeClass('active');
        $('.textVisit').each(function() {
            var textVisit = $(this).attr('data-number');
            if(dataNav == textVisit) {
                $(this).addClass('active');
                $('.dot[data-number=' + dataNav + ']').addClass('active');
            }
        })
    });

    if (mediaMobile.matches) {
        $('.textVisit:first-child').addClass('active');
        $('.dot:first-child').addClass('active');
    }

    $( window ).resize(function() {
        if (mediaMobile.matches) {
            $('.textVisit').removeClass('active');
            $('.dot').removeClass('active');
            $('.textVisit:first-child').addClass('active');
            $('.dot:first-child').addClass('active');
        } else {
            $('.textVisit:first-child').removeClass('active');
            $('.dot:first-child').removeClass('active');
        }
    });

    $('.dot').click(function() {
        $('.dot').removeClass('active');
        $('.textVisit').removeClass('active');
        $('html').addClass('openPopUp');
        $(this).addClass('active');

        var dotsVisit = $(this).attr('data-number');
        $('.textVisit').each(function() {
            var textVisit = $(this).attr('data-number');

            if(dotsVisit == textVisit) {
                $(this).addClass('active');
            }
        })
    });

    $('.closeCrossMobile').click(function() {
        $('.dot').removeClass('active');
        $('html').removeClass('openPopUp');
        $('.textVisit').removeClass('active');
    });

    // Accordeon tarif array

    $('.titleArray').click(function() {
        var heightArray = $(this).parent().find('.arrayContainer').outerHeight(true);
        console.log(heightArray);

        $(this).parent().find('.arrayContainer').slideToggle();

        $(this).find('.btnArray').toggleClass('active')

        if($(this).find('.btnArray').hasClass('active')) {
            $(this).find('.btnArray').html(pll_strings[$('body').attr('data-lang')]['close']);
        } else {
            $(this).find('.btnArray').html(pll_strings[$('body').attr('data-lang')]['prices']);
        }
    });


    // Accès au phare accordeon

    // var heightTitleAccess = $('.titleAccess').outerHeight(true);
    // var heightRowAccess = $('.rowAccess').outerHeight(true);

    $('.btnAccess').click(function() {
        var accessHeight = $(this).parent().parent().find('.accessHeight');
        var heightRow = $(this).parent().parent().find('.rowAccess').outerHeight(true);

        $(this).toggleClass('active');

        accessHeight.toggleClass('active');

        if(accessHeight.hasClass('active')) {
            accessHeight.css('height', heightRow);
        } else {
            accessHeight.css('height', '0');
        }

        if($(this).hasClass('active')) {
            $(this).html(pll_strings[$('body').attr('data-lang')]['close']);
        } else {
            $(this).html(pll_strings[$('body').attr('data-lang')]['seeaccess']);
        }
    });

    // Video page histoire
    $('.videoContainer').find('iframe').addClass('resp-iframe');


    // Slider page histoire

    $('.owl-history').owlCarousel({
        loop: true,
        nav: false,
        items: 1,
    });

    $('.owl-history-items').owlCarousel({
        loop: false,
        nav: false,
        items: 1,
        freeDrag: false,
        pullDrag: false,
        touchDrag: false,
        mouseDrag: false,
    });

    $('.owl-next-history').click(function () {
        $('.owl-history').trigger('next.owl.carousel');
    })

    $('.owl-prev-history').click(function () {
        $('.owl-history').trigger('prev.owl.carousel');
    })

    // Smooth anchor
    function juizScrollTo(element){
        $(element).click(function(){
            var goscroll = false;
            var the_hash = $(this).attr("href");
            var regex = new RegExp("\#(.*)","gi");
            var the_element = '';

            if(the_hash.match("\#(.+)")) {
                the_hash = the_hash.replace(regex,"$1");

                if($("#"+the_hash).length>0) {
                    the_element = "#" + the_hash;
                    goscroll = true;
                }
                else if($("a[name=" + the_hash + "]").length>0) {
                    the_element = "a[name=" + the_hash + "]";
                    goscroll = true;
                }

                if(goscroll) {
                    $('html, body').animate({
                        scrollTop:$(the_element).offset().top - 130
                    }, 'slow');
                    return false;
                }
            }
        });
    };
    juizScrollTo('a[href^="#"]');

    // sticky menu

    $('.anchorSticky:first-child').addClass('active');



    stickyHistory();
    $(window).resize(stickyHistory);
    $(window).scroll(stickyHistory);

    $(window).scroll(anchorScroll);
});

function anchorScroll() {

    if($('.menuSticky').length) {

        // On récupère la position du haut du menuSticky
        var children = $('.menuSticky').offset().top;

        // Pour chaque contenu (contentHistory)
        $('.contentHistory').each(function() {
            // On récupère le haut du contentHistory
            var topContent = $(this).offset().top;

            // On récupère le bas du contentHistory (en ajoutant sa hauteur au top)
            var bottomContent = $(this).offset().top + $(this).outerHeight(true);

            // Si children est compris entre le top et le bottom du contentHistory
            if(topContent <= children && children <= bottomContent) {
                // On récupère l'id de contentHistory (ajoute # pour qu'il puisse être égal au href de anchorSticky)
                var idContent = '#' + $(this).attr('id');

                // Pour chaque ancre
                $('.anchorSticky').each(function() {
                    // On récupère le href de celui sur lequel on est
                    var hrefSticky = $(this).attr('href');

                    // Si l'id est égal au href
                    if(idContent == hrefSticky) {

                        $('.anchorSticky').removeClass('active');
                        $(this).addClass('active');

                    }
                });
            }
        });
    }
}

function stickyHistory() {

    if($('.menuSticky').length) {
        // Haut de la page actuelle
        var windowTop = $(window).scrollTop();

        // Bas du menu
        var bottomDiv = $('.menuSticky').offset().top + $('.menuSticky').outerHeight(true);
        console.log(bottomDiv);

        // Début du sticky menu
        var topSticky = $('.first').offset().top;

        // Fin du sticky menu
        var bottomSticky = $('.prefooter').offset().top - 50;

        var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

        if(w > 767) {
            if(bottomDiv < bottomSticky) {
                $('.menuSticky').removeClass("opacity").addClass('sticky');

                if((windowTop + 50) < topSticky) {
                    $('.menuSticky').removeClass('sticky');
                }
            } else {
                $('.menuSticky').addClass('sticky').addClass("opacity");
            }
        }
    }
}
